import AddReceiver from "./AddReceiver.vue";

// const listModel = [
//     {
//         name: "债券渠道员工",
//         id: 217,
//         staff: [
//             {
//                 name: "员工A",
//                 id: 999,
//             },
//             {
//                 name: "员工B",
//                 id: 998
//             }
//         ]
//     },
//     {
//         name: "财务部",
//         id: 998,
//         staff: [
//             {
//                 name: "员工C",
//                 id: 997
//             },
//             {
//                 name: "员工D",
//                 id: 996
//             }
//         ]
//     },
//     {
//         name: "业务部",
//         id: 997,
//         staff: []
//     }
// ];

export default {
    name: 'NotifySetting',
    components: {AddReceiver},

    data () {
        return {
            PB: {
                list1: [],
                list2: [],
                list3: [],
                list4: [],
                list5: [],
            },
            currentList: null, // 正在编辑的list, 上面四个中的一个
            auth: {
                save: false,
            }
        }
    },

    computed: {
        _list1 () {
            return this.PB.list1.map((department, i) => {
                if (department.staff?.length) return department.staff.map((staff,j) => ({
                    name: [department.name, staff.name].join("-"),
                    index: [i,j]
                }))
                else return {
                    ...department,
                    index: [i]
                }
            }).flat();
        },
        _list2 () {
            return this.PB.list2.map((department, i) => {
                if (department.staff?.length) return department.staff.map((staff,j) => ({
                    name: [department.name, staff.name].join("-"),
                    index: [i,j]
                }))
                else return {
                    ...department,
                    index: [i]
                }
            }).flat();
        },
        _list3 () {
            return this.PB.list3.map((department, i) => {
                if (department.staff?.length) return department.staff.map((staff,j) => ({
                    name: [department.name, staff.name].join("-"),
                    index: [i,j]
                }))
                else return {
                    ...department,
                    index: [i]
                }
            }).flat();
        },
        _list4() {
            return this.PB.list4.map((department, i) => {
                if (department.staff?.length) return department.staff.map((staff, j) => ({
                    name: [department.name, staff.name].join("-"),
                    index: [i, j]
                }))
                else return {
                    ...department,
                    index: [i]
                }
            }).flat();
        },
        _list5() {
            return this.PB.list5.map((department, i) => {
                if (department.staff?.length) return department.staff.map((staff, j) => ({
                    name: [department.name, staff.name].join("-"),
                    index: [i, j]
                }))
                else return {
                    ...department,
                    index: [i]
                }
            }).flat();
        },
    },

    methods: {
        async fetchData () {
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/configuration/queryMessagePostList",
                })
				if (res.code !== 2000 && res.code !== 1003) throw res;

                res.data.messagePostList.forEach(post => {
                    this.PB[`list${post.type}`].push({
                        id: post.postId,
                        name: post.postName,
                        staff: post.empIds?.split(",").filter(Boolean).map((empid,index) => {
                            return {
                                id: Number(empid),
                                name: post.empNames.split(",")[index]
                            }
                        }) || []
                    })
                })
				
			} catch (reason) {
				console.warn("获取数据失败", reason);
				this.$message({
					message: reason.msg || "获取数据失败",
					type: "warning"
				});
			}
            
            loading.close();
        },

        handleSelectItemClick (list, index) {
            const [i,j] = index;
            if (j== undefined) {
                list.splice(i, 1)
            } else {
                list[i].staff.splice(j,1);

                if (!list[i].staff.length) list.splice(i, 1)
            }
        },

        handleAddButton (list) {
            this.currentList = list;
        },

        reRenderList(post) {
            const existIndex = this.currentList.findIndex(({id}) => id == post.id);
            const exist = this.currentList[existIndex];
            if (exist) {
                if (exist.staff.length  && post.staff.length) {
                    exist.staff.forEach(item => {
                        const isfind = post.staff.some(it => it.id == item.id);
                        if (!isfind) post.staff.unshift(item)
                    });
                }

                this.$set(this.currentList, existIndex, post);
            }
            else this.currentList.push(post);
        },

        async handleSubmitButton () {
            
			const loading = this.$loading();

            try {
                const res = await this.$axios({
                    method: "post",
                    url: "/api/configuration/saveMessagePost",
                    data: {
                        messagePostListStr: JSON.stringify([
                            ...this.PB.list1.map(post => {
                                return {
                                    type: 1,
                                    postid: post.id,
                                    empids: post.staff.length ? post.staff.map(({id})=>id).join() : ""
                                }
                            }),
                            ...this.PB.list2.map(post => {
                                return {
                                    type: 2,
                                    postid: post.id,
                                    empids: post.staff.length ? post.staff.map(({id})=>id).join() : ""
                                }
                            }),
                            ...this.PB.list3.map(post => {
                                return {
                                    type: 3,
                                    postid: post.id,
                                    empids: post.staff.length ? post.staff.map(({id})=>id).join() : ""
                                }
                            }),
                            ...this.PB.list4.map(post => {
                                return {
                                    type: 4,
                                    postid: post.id,
                                    empids: post.staff.length ? post.staff.map(({ id }) => id).join() : ""
                                }
                            }),
                            ...this.PB.list5.map(post => {
                                return {
                                    type: 5,
                                    postid: post.id,
                                    empids: post.staff.length ? post.staff.map(({ id }) => id).join() : ""
                                }
                            })
                        ])
                    }
                })
				if (res.code !== 2000 && res.code !== 1003) throw res;
                
				this.$message({
                    message: "保存成功",
					type: "success"
				});


			} catch (reason) {
                console.warn("保存失败", reason);
				this.$message({
                    message: reason.msg || "保存失败",
					type: "warning"
				});
			}

            loading.close();
        },
    },

    created () {
        this.auth.save = this.$hasAuthFor("/api/configuration/saveMessagePost");
		this.fetchData();
    }
}